<template>
  <div>
    <dashboard-navbar></dashboard-navbar>
    <div class="main-content">
      <div class="ai-content" style="flex-wrap: wrap;max-width: 720px;padding-right: 15px;padding-left: 15px;margin-left: auto;margin-right: auto;">
        <div style="width:100%;border: 1px solid #1f7ce5;border-radius: 20px;box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);margin-bottom:5px;">
          <a href="https://digitalplan.digitalstaging.co.jp/" 
            target="_blank" 
            style="text-align:center;margin: 5px 18px;">
            <div class="blue-text" style="font-size:10px"><img
              style="width:150px;"
              src="../../../public/img/home/plan.png"
              alt="Notification Bell"
            /><br>図面から360°バーチャルツアーを</div>
          </a>
        </div>
        <div style="width:100%;border: 1px solid #1f7ce5;border-radius: 20px;box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);">
          <a href="https://ai.digitalstaging.co.jp/" 
            target="_blank" 
            style="text-align:center;margin: 5px 18px;">
              <div class="blue-text" style="font-size:10px"><img
                style="width:150px;"
                src="../../../public/img/home/digitalstaging_ai_logo.png"
                alt="Notification Bell"
              /></div>
              <div class="blue-text" style="font-size:10px;">床・壁・天井の変更5,500円で無制限利用可能</div>
          </a>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-12 light-blue-text label-font-size">注文状況</div>
        </div>
      </div>
      <div v-if="reports" class="container">
        <div class="row reports">
          <div class="col-6 col-md-6 col-lg-6 col-xl-2 mb-4">
            <div
              class="report-item d-flex justify-content-center align-items-center"
            >
              <router-link :to="{ name: 'userRoute-staging-select-survice' }">
                <div>
                  <h5 class="new-request">
                    <img
                      src="../../../public/img/icons/ico-new-request.svg"
                      class="ico-new-request"
                      alt="New Request"
                    />
                    <span class="title">新規依頼</span>
                  </h5>
                </div>
              </router-link>
            </div>
          </div>
          <div class="col-6 col-md-6 col-lg-6 col-xl-2 mb-4">
            <div class="report-item">
              <div class="d-block">
                <h1 class="float-left icon">
                  <img
                    src="../../../public/img/icons/ico-request-accepted.svg"
                    alt="Request Accepted"
                  />
                </h1>
                <h1 class="float-right count">{{ reports.no_of_pending }}</h1>
              </div>
              <div class="d-block">
                <h5 class="title">依頼中</h5>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 col-lg-6 col-xl-2 mb-4">
            <div class="report-item">
              <div class="d-block">
                <h1 class="float-left icon">
                  <img
                    src="../../../public/img/icons/ico-pending-verification.svg"
                    alt="Pending Verification"
                  />
                </h1>
                <h1 class="float-right count">{{ reports.no_of_delivered }}</h1>
              </div>
              <div class="d-block">
                <h5 class="title">納品確認</h5>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 col-lg-6 col-xl-2 mb-4">
            <div class="report-item">
              <div class="d-block">
                <h1 class="float-left icon">
                  <img
                    src="../../../public/img/icons/ico-modification-request.svg"
                    alt="Modification Request"
                  />
                </h1>
                <h1 class="float-right count">
                  {{ reports.no_of_correction }}
                </h1>
              </div>
              <div class="d-block">
                <h5 class="title">修正依頼</h5>
              </div>
            </div>
          </div>
          <div class="col-6 col-md-6 col-lg-6 col-xl-2 mb-4">
            <div class="report-item">
              <div class="d-block">
                <h1 class="float-left icon">
                  <img
                    src="../../../public/img/icons/ico-confirmed.svg"
                    alt="confirmed"
                  />
                </h1>
                <h1 class="float-right count">{{ reports.no_of_confirmed }}</h1>
              </div>
              <div class="d-block">
                <h5 class="title">納品確認済</h5>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="
            !reports.no_of_confirmed &&
            !reports.no_of_pending &&
            !reports.no_of_delivered
          "
        >
          <div class="col-12 mt-5 text-center">
            <img
              src="/img/icons/icon-cloud-off-circle.svg"
              alt="icon-cloud-off-circle"
              style="height: 150px"
            />
            <h3 class="grey-text mt-4">依頼がありません</h3>
            <h4 class="grey-text">新規依頼をクリックして注文してください</h4>
          </div>
        </div>
        <div v-else>
          <div
            id="delivered-list"
            class="row mt-5"
            v-if="reports.recent_delivered_order !== null"
          >
            <div class="col-12 col-md-12 col-lg-12 col-xl-9 mb-3">
              <div class="row">
                <div class="col-12 mb-1 d-flex justify-content-between align-items-center">
                  <div class="blue-text float-left label-font-size">納品済</div>
                  <router-link :to="{ name: 'userRoute-staging-delivered' }">
                    <div class="blue-text float-right text-decoration-underline">
                      <span>すべて表示</span>
                    </div>
                  </router-link>
                </div>
                <div class="col-md-6">
                  <div class="image-wrapper">
                    <img
                      class="photo-item"
                      :src="
                        reports.recent_delivered_order.request_items[0].file_url
                      "
                      onerror="this.src='/img/image-error.png'"
                    />
                    <p class="mt-3 ml-3 font-weight-bold">
                      依頼日時:
                      <span class="ml-1 font-weight-normal">
                        {{
                          reports.recent_delivered_order.billing
                            ? formatDate(
                                reports.recent_delivered_order.billing.updated_at
                              )
                            : ''
                        }}
                      </span>
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="image-wrapper">
                    <img
                      class="photo-item"
                      :src="
                        getCurrentImageVersionUrl(
                          reports.recent_delivered_order.request_items[0]
                        )
                      "
                      onerror="this.src='/img/image-error.png'"
                    />
                    <p class="mt-3 ml-3 font-weight-bold">
                      納品日時:
                      <span class="ml-1 font-weight-normal">
                        {{
                          formatDate(reports.recent_delivered_order.delivery_date)
                        }}
                      </span>
                      <br />
                      修正・確認期限:
                      <span class="ml-1 font-weight-normal">
                        {{
                          formatDate(
                            reports.recent_delivered_order.deadline_date
                          )
                        }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-3 mb-3">
              <button
                class="btn btn-ds blue white-text font-weight-normal"
                type="button"
                v-waves.light
                style="margin-top: 34px"
                v-on:click="
                  setConfirmDeliveryModal(reports.recent_delivered_order.id)
                "
              >
                納品確認
              </button>
              <button
                class="btn btn-ds blue white-text mt-3 font-weight-normal"
                type="button"
                v-waves.light
                v-on:click="
                  downloadImages(reports.recent_delivered_order.request_items)
                "
              >
                ダウンロード
              </button>
              <router-link
                :to="{
                  name: 'userRoute-staging-delivered-correction-view',
                  params: {
                    id: reports.recent_delivered_order.id,
                  },
                }"
              >
                <button
                  class="btn btn-ds blue white-text mt-3 font-weight-normal"
                  type="button"
                  v-waves.light
                >
                  修正リクエスト
                </button>
              </router-link>
              <router-link
                :to="{
                  name: 'userRoute-staging-delivered-view',
                  params: {
                    id: reports.recent_delivered_order.id,
                  },
                }"
              >
                <button
                  class="btn btn-ds blue white-text mt-3 font-weight-normal"
                  type="button"
                  v-waves.light
                >
                  詳細を表示
                </button>
              </router-link>
            </div>
          </div>
          <div
            id="pending-list"
            class="row mt-5"
            v-if="reports.recent_pending_order !== null"
          >
            <div class="col-12 col-md-12 col-lg-12 col-xl-9 mb-3">
              <div class="row">
                <div class="col-12 mb-1 d-flex justify-content-between align-items-center">
                  <div class="blue-text float-left label-font-size">リクエスト中</div>
                  <router-link :to="{ name: 'userRoute-staging-pending' }">
                    <div class="blue-text float-right text-decoration-underline">
                      <span class="title">すべて表示</span>
                    </div>
                  </router-link>
                </div>
                <div class="col-6">
                  <div class="image-wrapper">
                    <img
                      class="photo-item"
                      :src="
                        reports.recent_pending_order.request_items[0].file_url
                      "
                      onerror="this.src='/img/image-error.png'"
                    />
                    <p class="mt-3 ml-3 font-weight-bold">
                      依頼日時:
                      <span class="ml-1 font-weight-normal">
                        {{
                          reports.recent_pending_order.billing
                            ? formatDate(
                                reports.recent_pending_order.billing.updated_at
                              )
                            : ''
                        }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-12 col-xl-3 mb-3">
              <router-link
                :to="{
                  name: 'userRoute-staging-pending-view',
                  params: {
                    id: reports.recent_pending_order.id,
                  },
                }"
              >
                <button
                  class="btn btn-ds blue white-text font-weight-normal"
                  type="button"
                  v-waves.light
                  style="margin-top: 34px"
                >
                  詳細確認
                </button>
              </router-link>
              <!--<button
                class="btn btn-ds blue white-text mt-3"
                type="button"
                v-waves.light
                v-on:click="setCancelItemModal(reports.recent_pending_order.id)"
              >
                キャンセル
              </button>-->
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row reports">
          <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4">
            <b-skeleton-img width="100%"></b-skeleton-img>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4">
            <b-skeleton-img width="100%"></b-skeleton-img>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4">
            <b-skeleton-img width="100%"></b-skeleton-img>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4">
            <b-skeleton-img width="100%"></b-skeleton-img>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-2 mb-4">
            <b-skeleton-img width="100%"></b-skeleton-img>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 col-md-12 col-lg-12 col-xl-9 mb-3">
            <div class="row">
              <div class="col-12 mb-2">
                <b-skeleton width="100%"></b-skeleton>
              </div>
              <div class="col-12">
                <b-skeleton-img width="100%"></b-skeleton-img>
                <b-skeleton class="mt-3" width="100%"></b-skeleton>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-12 col-xl-3 mb-3">
            <b-skeleton class="mb-3" type="button" width="100%"></b-skeleton>
            <b-skeleton class="mb-3" type="button" width="100%"></b-skeleton>
            <b-skeleton class="mb-3" type="button" width="100%"></b-skeleton>
            <b-skeleton class="mb-3" type="button" width="100%"></b-skeleton>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12 col-md-12 col-lg-12 col-xl-9 mb-3">
            <div class="row">
              <div class="col-12 mb-2">
                <b-skeleton width="100%"></b-skeleton>
              </div>
              <div class="col-12">
                <b-skeleton-img width="100%"></b-skeleton-img>
                <b-skeleton class="mt-3" width="100%"></b-skeleton>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-12 col-xl-3 mb-3">
            <b-skeleton class="mb-3" type="button" width="100%"></b-skeleton>
            <b-skeleton class="mb-3" type="button" width="100%"></b-skeleton>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="cancel-item-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="$bvModal.hide('cancel-item-modal')"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          この依頼をキャンセルしてもよろしいですか？
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
            <button
              class="btn btn-ds light-grey white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('cancel-item-modal')"
            >
              <span>キャンセル</span>
            </button>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="removeItem"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="confirm-delivery-modal"
      class="border-radius-2"
      size="md"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <h5
        class="grey-text dark-blue-text-hover float-right pl-1 pr-1"
        v-on:click="$bvModal.hide('confirm-delivery-modal')"
      >
        <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'times' }" />
      </h5>
      <div class="container text-center">
        <img class="mt-4" src="/img/icons/ico-info.svg" alt="ico-info" />
        <h5 class="mt-4 mb-0 dark-blue-text font-weight-bold">
          編集された写真を承認しますか？
        </h5>
        <div class="row mb-3 mt-5">
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
            <button
              class="btn btn-ds light-grey white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="$bvModal.hide('confirm-delivery-modal')"
            >
              <span>キャンセル</span>
            </button>
          </div>
          <div class="col-12 col-md-6 col-lg-6 col-xl-6 mb-2">
            <button
              class="btn btn-ds dark-blue white-text pt-2 pb-2"
              type="button"
              v-waves.light
              v-on:click="confirmDelivery"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <main-footer></main-footer>
  </div>
</template>

<script>
// Import Services
import { cus_dashboardService_GetDashboardData } from '../../services/customer/dashboard';

// Import Custom Components
import dashboardNavbar from '../../components/customer/Dashboard-Navbar.vue';
import mainFooter from '../../components/customer/Main-Footer.vue';

import { cus_stagingService_DeleteSR } from '../../services/customer/staging';
import { formatDate } from '../../common/Utility';

import { cus_stagingService_CreateSRConfirmDelivery } from '../../services/customer/staging';
import { getRequestItemVersions } from '../../common/Functions';
import { globalService_DownloadIMG } from '../../services/global';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Dashboard',
    };
  },

  components: {
    'dashboard-navbar': dashboardNavbar,
    'main-footer': mainFooter,
  },

  data() {
    return {
      reports: null,
      itemToBeConfirm: null,
      isMix:false,
    };
  },

  mounted() {
    this.getDashboardData();
  },

  methods: {
    formatDate,
    getDashboardData() {
      let _this = this;

      let accessToken = localStorage.getItem('_accessToken');

      cus_dashboardService_GetDashboardData({ token: accessToken })
        .then((response) => {
          let data = response.data.result;
          console.log(data);

          _this.reports = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setCancelItemModal(id) {
      this.itemToBeRemoved = id;
      this.$bvModal.show('cancel-item-modal');
    },

    removeItem() {
      let _this = this;

      _this.$bvModal.hide('cancel-item-modal');

      _this.$store.state.modalLoaderMessage = 'キャンセル中';
      _this.$bvModal.show('modal-loader');

      let accessToken = localStorage.getItem('_accessToken');

      cus_stagingService_DeleteSR({
        id: _this.itemToBeRemoved,
        token: accessToken,
      })
        .then((response) => {
          console.log(response);

          _this.setTableData();

          _this.$bvToast.toast('依頼をキャンセルしました。', {
            title: 'Success Canceled',
            autoHideDelay: 5000,
            variant: 'success',
            appendToast: true,
          });

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          _this.$bvToast.toast(
            '依頼のキャンセル中にエラーが起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },
    setConfirmDeliveryModal(id) {
      this.itemToBeConfirm = id;
      this.$bvModal.show('confirm-delivery-modal');
    },
    confirmDelivery() {
      let _this = this;

      _this.$bvModal.hide('confirm-delivery-modal');

      _this.$store.state.modalLoaderMessage = '確認中';
      _this.$bvModal.show('modal-loader');

      let accessToken = localStorage.getItem('_accessToken');

      cus_stagingService_CreateSRConfirmDelivery({
        id: _this.itemToBeConfirm,
        token: accessToken,
      })
        .then((response) => {
          console.log(response);

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
            _this.$router.push({
              name: 'userRoute-staging-confirmed',
            });
          }, 500);
        })
        .catch((error) => {
          console.log(error);

          _this.$bvToast.toast(
            '依頼の確認中にエラーが起こりました。再度お試しください。',
            {
              title: 'システムエラー',
              autoHideDelay: 5000,
              variant: 'danger',
              appendToast: true,
            }
          );

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);
        });
    },
    downloadImages(requestItems) {
      let link = document.createElement('a');
      document.body.appendChild(link);

      let accessToken = localStorage.getItem('_accessToken');

      //download current confirmed image for each request items
      requestItems.forEach((ri) => {
        const { current_version } = getRequestItemVersions(ri);
        globalService_DownloadIMG({
          token: accessToken,
          path: current_version.result_file_path,
          isMix:this.isMix,
        }).then((response) => {
          let url = window.URL.createObjectURL(response.data);
          link.href = url;
          link.setAttribute(
            'download',
            `SR-${ri.id}_current_version_${current_version.delivery_date}`
          );
          link.click();
        });
      });
    },
    getCurrentImageVersionUrl(requestItem) {
      const { current_version } = getRequestItemVersions(requestItem);
      return current_version ? current_version.result_file_url : '';
    },
  },
};
</script>

<style scoped>
/* Init Style */
.main-content {
  padding: 110px 30px 150px 330px;
  font-size: 12px;
}

@media print {
  .main-content {
    padding: 0px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 991px) {
  .main-content {
    padding: 90px 35px 80px 35px;
  }
}

@media only screen and (max-width: 767px) {
  .main-content {
    padding: 90px 10px 80px 10px;
  }
}

/* Reports */
@media only screen and (min-width: 1450px) {
  .reports .col-xl-2 {
    flex: 40 0 16.666667%;
    max-width: 30.666667%;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1449px) {
  .reports .col-xl-2 {
    flex: 0 0 24.333333%;
    max-width: 20%;
  }
}

.reports .col-6 .report-item {
  height: 150px;
  width: 100%;
  padding: 0px 40px 0px 40px;
  color: #fff;
  border-radius: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media only screen and (max-width: 1440px) {
  .reports .col-6 .report-item {
    padding: 10px 20px 10px 20px;
  }
}

.reports div:nth-child(1) .report-item {
  background: #fff;
  border: 1px solid #1f7ce5;
  color: #1f7ce5;
  padding: 0;
  width: 100%;
  text-align: center;
}

.reports div:nth-child(2) .report-item {
  background: #1f7ce5;
}

.reports div:nth-child(3) .report-item {
  background: #0a64c7;
}

.reports div:nth-child(4) .report-item {
  background: #034ea1;
}

.reports div:nth-child(5) .report-item {
  background: #034ea1;
}

.reports .col-6 .report-item .d-block:nth-child(1) {
  height: 100px;
  width: 100%;
}

.reports .col-6 .report-item .d-block:nth-child(2) {
  height: 50px;
  width: 100%;
}

.reports .col-6 .report-item .icon {
  margin-top: 25px;
}

.reports .col-6 .report-item .count {
  margin-top: 14px;
  font-size: 60px;
}

@media only screen and (max-width: 1599px) {
  .reports .col-6 .report-item .title {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1440px) {
  .reports .col-6 .report-item .icon {
    font-size: 40px;
  }

  .reports .col-6 .report-item .count {
    margin-top: 10px;
    font-size: 60px;
  }
}

.image-wrapper {
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 15px;
  height: 320px;
}

@media only screen and (max-width: 1449px) {
  .image-wrapper {
    height: 270px;
  }
}

@media only screen and (max-width: 767px) {
  .image-wrapper {
    height: 320px;
  }
}

@media only screen and (max-width: 425px) {
  .image-wrapper {
    height: 270px;
  }
}

#delivered-list .photo-item,
#pending-list .photo-item {
  height: 250px;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

@media only screen and (max-width: 1449px) {
  #delivered-list .photo-item,
  #pending-list .photo-item {
    height: 200px;
  }
}

@media only screen and (max-width: 767px) {
  #delivered-list .photo-item,
  #pending-list .photo-item {
    height: 250px;
  }
}

@media only screen and (max-width: 425px) {
  #delivered-list .photo-item,
  #pending-list .photo-item {
    height: 200px;
  }
}

.ico-new-request {
  margin-right: 11px;
}

.label-font-size {
  font-size: 16px;
}
.ai-content {
  display: none;
}

@media only screen and (max-width: 991px) {
  .ai-content {
    display: flex;
    justify-content: center;
  }
}
</style>
